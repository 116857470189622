import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import { IProject, TagEntity } from '../interfaces/IProject';

const Fullscreen = ({
  pageContext: { project },
}: {
  pageContext: { project: IProject };
}) => {
  return (
    <Layout>
      <div className="container py-5 px-4">
        <Img
          fluid={project.node.imgUrl.childImageSharp.fluid}
          className="rounded-3 shadow-box mb-3"
        />
        <div className="rounded-3 shadow-box mb-3 p-4">
          <h1>{project.node.title}</h1>
          <p>{project.node.description}</p>
          <div>
            {project.node.tag?.map((tag: TagEntity) => (
              <span
                className={`badge rounded-pill ${tag.class} me-1`}
                key={tag.name}
              >
                {tag.name}
              </span>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded-3 shadow-box mb-3 p-4">
          <span>You can visit by click on visit button</span>
          <Link to={project.node.redirect} className="btn btn-dark">
            Visit
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Fullscreen;
